.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.walletButton {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px;
  background-color: rgb(44, 47, 54);
  border: 1px solid rgb(64, 68, 79);
  margin: 5px;
  font-size: 18px;
  border-radius: 12px;

  img {
    width: 24px;
  }

  &:hover {
    border: 1px solid rgb(33, 114, 229)
  }
}

.dropdown {
  position: relative;

  .dropbtn {
    font-family: 'Poppins';
    font-weight: 300;
    font-size: 16px;
    line-height: 29px;
    position: relative;
    background-color: black;
    border: 2px solid #252525;
    color: #F2F2F2;
    border-radius: 70px;
    width: 200px;
    // height: 57px;
    padding: 15px 30px 15px 30px;
  }
}

.dropbtn:hover {
  background-color: #10CD00;
  color: black;
}

.dropdown-content {
  width: 200px;
  padding: 10px;
  font-size: 16px;
  display: none;
  position: absolute;
  border-radius: 12px;
  top: 35px;
  right: 0;
  background-color: white;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 999;
}

.dropdown-content button {
  text-align: center;
  border: 1px gray solid;
  padding: 3px 10px;
  border-radius: 12px;
  width: 100%;
  margin: 5px 0px;
  text-decoration: none;
  display: block;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-around;

  &:hover {
    border: 1px blue solid;
    color: blue;
  }
}

.dropdown:hover .dropdown-content {
  display: block;
}

.text-center {
  text-align: center !important;
}

.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #000;
  border: 1px solid rgb(51, 51, 51);
}

.react-tel-input .flag-dropdown.open .selected-flag {
  background-color: #000;
  border: 1px solid rgb(51, 51, 51);
  border-radius: 3px 0 0 0;
}

.react-tel-input .country-list .country:hover {
  background-color: rgb(51, 51, 51);
}

.react-tel-input .country-list .country.highlight {
  background-color: rgb(51, 51, 51);
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, .15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, .15) 50%, rgba(255, 255, 255, .15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}